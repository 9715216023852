




































import Vue from 'vue';

// components
import DailyNote from '@/components/molecules/dailyNote/DailyNote.vue';

import { todayDailyNoteModule } from '@/store/viewModule/today/todayDailyNoteModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import dayjs from 'dayjs';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';

export default Vue.extend({
  name: 'TodayJournalDialogContainer',

  components: {
    DailyNote,
  },

  data: () => {
    return {
      newNote: '',
      editTimerId: null,
      createTimerId: null,
      opendNote: null,
    };
  },

  computed: {
    isOpen() {
      return todayDailyNoteModule.selectedUserId !== null;
    },
    isMine() {
      const userId = todayDailyNoteModule.selectedUserId;
      if (userId) {
        if (userId === userModule.user.nippoUserId) {
          return true;
        }
      }
    },
    myJournal() {
      return journalModule.todayJournal;
    },
    userJournal() {
      const userId = todayDailyNoteModule.selectedUserId;
      const today = dayjs().format('YYYY-MM-DD');
      if (userId && !this.isMine) {
        const find = journalViewModule.teamDailyNotes.find((n) => n.user_id === userId && n.epoch === today);
        if (find) {
          return find;
        }
      }
      return null;
    },
    selectedUserName() {
      const userId = todayDailyNoteModule.selectedUserId;
      if (userId) {
        if (userId === userModule.user.nippoUserId) {
          return userModule.user.username;
        }
        const find = userModule.teamUsers.find((u) => u.nippoUserId === userId);
        if (find) {
          return find.username;
        }
      }

      return null;
    },
  },

  methods: {
    close() {
      setTimeout(() => {
        if (this.isMine && this.myJournal) {
          if (this.myJournal.body !== this.opendNote) {
            journalViewModule.sentMqttNotification(this.myJournal);
            journalViewModule.sentPushNotification();
          }
        }
        this.opendNote = null;
      }, 3000);
      todayDailyNoteModule.setSelectedUserId(null);
    },
    editNote(value) {
      if (userModule.user && this.myJournal) {
        if (this.editTimerId) {
          clearTimeout(this.editTimerId);
        }

        this.editTimerId = window.setTimeout(() => {
          journalModule.updateDailyNote({
            documentId: this.myJournal.document_id,
            date: this.myJournal.epoch,
            teamId: userModule.user.teamId,
            body: value,
          });
        }, 500);
      }
    },
  },

  watch: {
    newNote() {
      if (this.createTimerId) {
        clearTimeout(this.createTimerId);
      }

      const today = dayjs().format('YYYY-MM-DD');
      this.createTimerId = window.setTimeout(() => {
        journalModule.createDailyNote({
          date: today,
          teamId: userModule.user.teamId,
          body: this.newNote,
        });
      }, 500);
    },
    isOpen() {
      if (this.isOpen) {
        if (this.myJournal && this.myJournal.body) {
          this.opendNote = this.myJournal.body;
        } else {
          this.opendNote = null;
        }
      }
    },
  },
});
