


























































import Vue from 'vue';

// store
import { teamModule } from '@/store/dataModule/team/teamModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { todayViewModule, ChannelWithProgress } from '@/store/viewModule/today/todayViewModule';

// services
import LocalDataService from '@/service/LocalDataServices';
import { calcDoneRate } from '@/service/ui/StatsService';
import { getProgressColor } from '@/service/StyleService';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { channelCreateModule } from '@/store/viewModule/channel/channelCreateViewModule';

import { sendEvent } from '@/service/GoogleAnalyticsService';

export default Vue.extend({
  name: 'TodayChannelsContainer',

  components: {
  },

  computed: {
    myChannels(): ChannelWithProgress[] {
      return todayViewModule.myChannelsWithProgress.sort((c1, c2) => {
        const name1 = c1.channel.channelName;
        const name2 = c2.channel.channelName;
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      });
    },
    selectedChannelId(): string {
      return todayViewModule.selectedChannelId;
    },
    allProgress(): number {
      return todayViewModule.allTodayProgress;
    },
    presenceUserLength(): number {
      return todayViewModule.allPresenceUserLength;
    },
  },

  methods: {
    selectChannel(channelId: string) {
      todayViewModule.selectChannel(channelId);

      sendEvent('today_channel_show', {});
    },
    getProgressColor(progress: number) {
      return getProgressColor(progress);
    },
    createChannel() {
      channelCreateModule.setIsCreateDialogOpen(true);

      sendEvent('today_channel_create', {});
    },
  },

  async mounted() {
    todayViewModule.listChannels();
  },
});
