























import Vue from 'vue';

// components
import DesktopAppContainer from '@/container/common/DesktopAppContainer.vue';
import TodayGridContainer from '@/container/today/TodayGridContainer.vue';
import TodayChannelsContainer from '@/container/today/TodayChannelsContainer.vue';
import TodayChatsContainer from '@/container/today/TodayChatsContainer.vue';

// mobile
import MobileAppContainer from '@/container/common/MobileAppContainer.vue';
import MobileTodayContainer from '@/container/today/mobile/MobileTodayContainer.vue';

// service
import { isMobile } from '@/service/StyleService';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';

export default Vue.extend({
  name: 'TodayScene',

  components: {
    DesktopAppContainer,
    MobileAppContainer,
    TodayGridContainer,
    TodayChannelsContainer,
    TodayChatsContainer,
    MobileTodayContainer,
  },

  computed: {
    isMobile() {
      return isMobile(window);
    },
  },

  mounted() {
    if (window.innerWidth > 1280) {
      todayViewModule.setIsChatMinified(false);
    }
  },
});
