




























































































import Vue from 'vue';

// types
import { RemonadeDailyNote, TaskItem, TodayUser } from '@/model/types';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { reportsModule } from '@/store/dataModule/report/reportsModule';

// components
import TodayTaskCard from '@/components/molecules/today/TodayTaskCard.vue';
import TodayUserCard from '@/components/molecules/today/TodayUserCard.vue';
import EmojiPicker from '@/components/molecules/common/EmojiPicker.vue';

import { isMobile } from '@/service/StyleService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { shortenJournal } from '@/service/ui/ModelService';
import { todayDailyNoteModule } from '@/store/viewModule/today/todayDailyNoteModule';
import dayjs from 'dayjs';
import { sendEvent } from '@/service/GoogleAnalyticsService';
import { userModule } from '@/store/dataModule/user/userModule';

interface DataType {
  taskCardUserId: string | null;
  emojiPickerUserId: string | null;
}

interface TodayUserWithJournal {
  user: TodayUser;
  journal: string | null;
}

export default Vue.extend({
  name: 'TodayProgressContainer',

  components: {
    TodayTaskCard,
    TodayUserCard,
    EmojiPicker,
  },

  data: (): DataType => {
    return {
      taskCardUserId: null,
      emojiPickerUserId: null,
    };
  },

  computed: {
    activeUsers() {
      return todayViewModule.activeUsers;
    },
    teamNotes() {
      return journalViewModule.teamDailyNotes;
    },
    activeUsersWithJournal(): TodayUserWithJournal[] {
      const today = dayjs().format('YYYY-MM-DD');
      return todayViewModule.sortedActiveUsers.map((user) => {
        if (journalViewModule.teamDailyNotes) {
          const find = journalViewModule.teamDailyNotes.find((note) => note.user_id === user.nippoUserId && note.epoch === today);
          return {
            user,
            journal: find ? shortenJournal(find.body) : null,
          };
        }
        return {
          user,
          journal: null,
        };
      });
    },
    inactiveUsers() {
      return todayViewModule.inactiveUsers;
    },
    inactiveUsersWithJournal(): TodayUserWithJournal[] {
      const today = dayjs().format('YYYY-MM-DD');
      return todayViewModule.inactiveUsers.map((user) => {
        if (journalViewModule.teamDailyNotes) {
          const find = journalViewModule.teamDailyNotes.find((note) => note.user_id === user.nippoUserId && note.epoch === today);
          return {
            user,
            journal: find ? shortenJournal(find.body) : null,
          };
        }
        return {
          user,
          journal: null,
        };
      });
    },
    me() {
      return todayViewModule.me;
    },
    meWithJournal(): TodayUserWithJournal | null {
      if (todayViewModule.me) {
        const journal = journalModule.todayJournal ? journalModule.todayJournal.body : null;
        return {
          user: todayViewModule.me,
          journal: shortenJournal(journal),
        };
      }
      return null;
    },
    tags() {
      return tagModule.activatedTags;
    },
    tasks() {
      return todayViewModule.todayTasks;
    },
    taskCardUser(): TodayUser | null {
      if (this.taskCardUserId) {
        if (this.me && this.taskCardUserId === this.me.userId) {
          return this.me;
        }
        const find = this.activeUsers.find((user) => user.userId === this.taskCardUserId);
        if (find) {
          return find;
        }
      }
      return null;
    },
    // styles
    taskCardStyle(): object | null {
      if (this.taskCardUserId) {
        const target = this.$refs[`userCard-${this.taskCardUserId}`];
        if (target) {
          return this.getFloatingCardStyle(target);
        }
      }
      return null;
    },
    emojiPickerStyle(): object | null {
      if (this.emojiPickerUserId) {
        const target = this.$refs[`userCard-${this.emojiPickerUserId}`];
        if (target) {
          return this.getFloatingCardStyle(target);
        }
      }
      return null;
    },
    isMobile(): boolean {
      return isMobile(window);
    },
  },

  methods: {
    updateTaskDone(task: TaskItem) {
      if (this.me) {
        if (task.userId !== this.me.userId) {
          return;
        }
        // この処理、reportModuleでやるべき
        const report = reportsModule.reports.find((r) => r.reportId === task.taskId);
        if (report) {
          if (task.isDone !== null && task.isDone !== undefined) {
            report.isDone = task.isDone;
          }
          reportsModule.updateTaskDone(report);
        }
        sendEvent('task_done_card', {});
      }
    },
    getUserTasks(userId: string) {
      return this.tasks.filter((taskItem) => taskItem.userId === userId);
    },
    openTasks(userId: string) {
      this.emojiPickerUserId = null;
      this.taskCardUserId = userId;
    },
    openEmoji(userId: string) {
      this.taskCardUserId = null;
      this.emojiPickerUserId = userId;
    },
    pickEmoji(emoji: string) {
      userModule.updateSnapContent(emoji);
    },
    resetFocus() {
      this.taskCardUserId = null;
      this.emojiPickerUserId = null;
    },
    clickStats(user: TodayUser) {
      statsViewModule.selectUser(user.userId);

      sendEvent('today_open_stats', {});
    },
    clickJournal(user: TodayUser) {
      todayDailyNoteModule.setSelectedUserId(user.nippoUserId);

      sendEvent('today_open_journal', {});
    },
    // styles
    getFloatingCardStyle(target: Vue | Element | Vue[] | Element[]): object {
      const windowWidth = window.innerWidth;
      let top = 0;
      let left = 0;
      if (Array.isArray(target)) {
        const div = target[0] as HTMLDivElement;
        top = this.isMobile ? div.getBoundingClientRect().top + 300 : div.getBoundingClientRect().top;
        left = this.isMobile ? div.getBoundingClientRect().left + 32 : div.getBoundingClientRect().left - 200;
      } else {
        const div = target as HTMLDivElement;
        top = this.isMobile ? div.getBoundingClientRect().top + 300 : div.getBoundingClientRect().top;
        left = this.isMobile ? div.getBoundingClientRect().left + 32 : div.getBoundingClientRect().left - 200;
      }
      return {
        top: top + 'px',
        left: left + 'px',
      };
    },
  },
});
