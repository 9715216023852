


















































































import Vue from 'vue';
import { userModule } from '@/store/dataModule/user/userModule';
import { UserResponse } from 'remonade-api/lib';
import CaptureService from '@/service/CaptureService';

interface DataType {
  isSettingMenuOpen: boolean;
}

export default Vue.extend({
  name: 'TodaySettingContainer',

  components: {
  },

  data: (): DataType => {
    return {
      isSettingMenuOpen: false,
    };
  },

  computed: {
    me(): UserResponse | null {
      return userModule.user;
    },
  },

  methods: {
    updateInterval() {
      if (this.me) {
        const newValue = CaptureService.updateInterval(this.me.interval);
        userModule.updateInterval(newValue);
      }
    },
    updatePixel() {
      if (this.me) {
        const newValue = CaptureService.updatePixel(this.me.pixel);
        userModule.updatePixel(newValue);
      }
    },
    updateSnapType() {
        if (this.me && this.me.snapType) {
        switch (this.me.snapType) {
          case 0:
            userModule.updateSnaptype(1);
            break;
          case 1:
            userModule.updateSnaptype(2);
            break;
          case 2:
            userModule.updateSnaptype(0);
            break;
          default:
            break;
        }
      } else {
        userModule.updateSnaptype(1);
      }
    },
  },

  async mounted() {
  },
});
