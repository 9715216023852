



































































import Vue from 'vue';
import { GChart } from 'vue-google-charts';
import UserTaskCalendarContainer from '@/container/stats/UserTaskCalendarContainer.vue';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import dayjs from 'dayjs';


export default Vue.extend({
  name: 'SelectedUserStatsContainer.vue',

  components: {
    GChart,
    UserTaskCalendarContainer,
  },

  data() {
    return {
      showWeekends: false,
    };
  },

  computed: {
    isDialogOpen() {
      return statsViewModule.selectedUserId !== null;
    },
    selectedUser() {
      return statsViewModule.selectedUser;
    },
    completionAndHourData() {
      return statsViewModule.selectedUserCompletionAndHourData;
    },
    tasksByTagData() {
      return statsViewModule.selectedUserTasksByTagData;
    },
    tasksByDayData() {
      return statsViewModule.selectedUserTasksByDayData;
    },
    startDate() {
      return statsViewModule.startDate;
    },
    endDate() {
      return statsViewModule.endDate;
    },
    tags() {
      return tagModule.activatedTags;
    },
    currentDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    weekdays() {
      if (this.showWeekends) {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    minDate() {
      return statsViewModule.minDate;
    },
  },

  methods: {
    getDateTasks(date: string) {
      return this.reports
              .filter((r) => (r.type === 'task') && (r.date === date) && (r.parentId === null))
              .sort((t1, t2) => (t1.displayOrder || 0) - (t2.displayOrder || 0));
    },
    getTag(tagId: string, key: string) {
      const find = this.tags.find((tag) => tag.id === tagId);
      if (find) {
        if (key === 'name') {
          return find.label;
        } else if (key === 'color') {
          return find.color;
        }
        return null;
      }
      return null;
    },
    close() {
      statsViewModule.selectUser(null);
    },
    isDateInRange(dateString: string) {
      const date = dayjs(dateString);
      if (!this.minDate) {
        return true;
      } else {
        const minDate = dayjs(this.minDate);
        if (date.diff(minDate) >= 0) {
          return true;
        }
        return false;
      }
    },
  },
});
