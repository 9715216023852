
































































































































































































import { bookmarkModule } from '@/store/dataModule/bookmark/bookmarkModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { BookmarkResponse } from 'remonade-api/lib';
import Vue from 'vue';

import { sendEvent } from '@/service/GoogleAnalyticsService';

interface DataType {
  isBookmarkMenuOpen: boolean;
  isCreateBookmarkDialogOpen: boolean;
  newBookmarkUrl: string | null;
  newbookmarkTitle: string | null;
  editingBookmark: BookmarkResponse | null;
  isUpdating: boolean;
  isCreating: boolean;
}

export default Vue.extend({
  name: 'TodayBookmarkContainer',


  data: (): DataType => {
    return {
      isBookmarkMenuOpen: false,
      isCreateBookmarkDialogOpen: false,
      newBookmarkUrl: null,
      newbookmarkTitle: null,
      editingBookmark: null,
      isCreating: false,
      isUpdating: false,
    };
  },

  computed: {
    isCreateSaveButtonValid() {
      if (!this.newbookmarkTitle || !this.newBookmarkUrl) {
        return false;
      }

      return (
        this.newBookmarkUrl.trim().length > 0 &&
        this.newbookmarkTitle.trim().length > 0
      );
    },
    bookmarks() {
      return todayViewModule.groupBookmarks;
    },
  },

  methods: {
    clickBookmark(bookmark: BookmarkResponse) {
      window.open(bookmark.url, '_blank');
      sendEvent('bookmark_open_url', {});
    },
    editBookmark(bookmark: BookmarkResponse) {
      this.editingBookmark = {...bookmark};
    },
    getIconLink(bookmark: BookmarkResponse) {
      if (bookmark.icon) {
        if (bookmark.icon.startsWith('http')) {
          return bookmark.icon;
        } else if (bookmark.icon.startsWith('/')) {
          return bookmark.url + bookmark.icon;
        }
        return bookmark.url + '/' + bookmark.icon;
      }
      return '/images/icons/icon-512x512.svg';
    },
    shortenUrl(url: string) {
      if (url.length > 30) {
        return url.slice(0, 30) + '..';
      }
      return url;
    },
    async saveButtonClicked() {
      if (this.newBookmarkUrl && this.newbookmarkTitle) {
        this.isCreating = true;
        await todayViewModule.createBookmark({
          title: this.newbookmarkTitle,
          url: this.newBookmarkUrl,
        });
        this.newbookmarkTitle = null;
        this.newBookmarkUrl = null;
        this.isCreateBookmarkDialogOpen = false;
        this.isCreating = false;

        sendEvent('bookmark_create', {});
      }
    },
    async editSaveButtonClicked() {
      if (this.editingBookmark) {
        this.isUpdating = true;
        await bookmarkModule.updateBookmark({
          bookmarkId: this.editingBookmark.bookmarkId,
          channelId: this.editingBookmark.channelId,
          url: this.editingBookmark.url,
          title: this.editingBookmark.title,
          description: this.editingBookmark.description,
          icon: this.editingBookmark.icon,
        });
        this.editingBookmark = null;
        this.isUpdating = false;

        sendEvent('bookmark_edit', {});
      }
    },
    async deleteButtonClicked(bookmark: BookmarkResponse) {
      commonModule.setLoader(true);
      await bookmarkModule.deleteBookmark(bookmark.bookmarkId);
      commonModule.setLoader(false);

      sendEvent('bookmark_delete', {});
    },
  },
});
