import { render, staticRenderFns } from "./TodayChannelsContainer.vue?vue&type=template&id=f54a9326&scoped=true&"
import script from "./TodayChannelsContainer.vue?vue&type=script&lang=ts&"
export * from "./TodayChannelsContainer.vue?vue&type=script&lang=ts&"
import style0 from "./TodayChannelsContainer.vue?vue&type=style&index=0&id=f54a9326&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f54a9326",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VProgressLinear,VSubheader})
