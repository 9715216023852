

























































































































































































































































































import Vue, { PropType } from 'vue';

import {
  SelectOptionItem,
} from '@/model/types';
import { ScheduledReportResponse } from 'remonade-api/lib';

const dec2bin = (dec: number) => {
  return (dec >>> 0);
};

export default Vue.extend({
  name: 'RecursiveTaskMenu',

  props: {
    hours: {
      required: true,
      type:  Array as PropType<number[]>,
    },
    tags: {
      required: true,
      type:  Array as PropType<SelectOptionItem[]>,
    },
    tasks: {
      required: true,
      type: Array as PropType<ScheduledReportResponse[]>,
    },
    newTask: {
      required: true,
      type: Object as PropType<{
        task: string;
        hour?: number | null | undefined;
        tag?: string | null | undefined;
        weekday: number;
      }>,
    },
  },

  data: () => {
    return {
      isRecursiveTaskMenuOpen: false,
    };
  },

  methods: {
    calcBit(day: number) {
      return dec2bin(day);
    },
    monValue(bit: number) {
      return (bit & 0b1) === 1;
    },
    tueValue(bit: number) {
      return (bit & 0b10) === 0b10;
    },
    wedValue(bit: number) {
      return (bit & 0b100) === 0b100;
    },
    thuValue(bit: number) {
      return (bit & 0b1000) === 0b1000;
    },
    friValue(bit: number) {
      return (bit & 0b10000) === 0b10000;
    },
    satValue(bit: number) {
      return (bit & 0b100000) === 0b100000;
    },
    sunValue(bit: number) {
      return (bit & 0b1000000) === 0b1000000;
    },
    updateScheduleDays(operand: number, task: ScheduledReportResponse, isNewTask = false) {
      const weekday = task.weekday || 0;
      const emitName = isNewTask ? 'updateNewTask' : 'updateTask';
      const newTask = isNewTask ? {...this.newTask} : {...task};
      if (this.calcBit(weekday) && operand) {
        newTask.weekday = this.calcBit(weekday) ^ operand;
        this.$emit(emitName, newTask);
      } else {
        newTask.weekday = this.calcBit(weekday) | operand;
        this.$emit(emitName, newTask);
      }
    },
    updateTaskName(name: string, task: ScheduledReportResponse, isNewTask = false) {
      const emitName = isNewTask ? 'updateNewTask' : 'updateTask';
      const newTask = isNewTask ? {...this.newTask} : {...task};
      newTask.task = name;
      this.$emit(emitName, newTask);
    },
    updateTaskTag(tagId: string, task: ScheduledReportResponse, isNewTask = false) {
      const emitName = isNewTask ? 'updateNewTask' : 'updateTask';
      const newTask = isNewTask ? {...this.newTask} : {...task};
      newTask.tag = tagId;
      this.$emit(emitName, newTask);
    },
    updateTaskHour(hour: number, task: ScheduledReportResponse, isNewTask = false) {
      const emitName = isNewTask ? 'updateNewTask' : 'updateTask';
      const newTask = isNewTask ? {...this.newTask} : {...task};
      newTask.hour = hour;
      this.$emit(emitName, newTask);
    },
    createNewTask() {
      this.$emit('createNewTask');
    },
    deleteTask(task: ScheduledReportResponse) {
      this.$emit('deleteTask', task);
    },
  },
});
