












import Vue from 'vue';

// types
import { hours } from '@/service/ui/ModelService';
import { ScheduledReportResponse } from 'remonade-api/lib';

// store
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { scheduledReportModule } from '@/store/dataModule/report/scheduledReportModule';

// components
import RecursiveTaskMenu from '@/components/organisms/today/RecursiveTaskMenu.vue';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';

interface DataType {
  hours: number[];
  newTask: {
    task: string;
    hour?: number | null | undefined;
    tag?: string | null | undefined;
    weekday: number;
  };
}

export default Vue.extend({
  name: 'RecursiveTaskContainer',

  components: {
    RecursiveTaskMenu,
  },

  data: (): DataType => {
    return {
      hours,
      newTask: {
        task: '',
        hour: null,
        tag: null,
        weekday: 0,
      },
    };
  },

  computed: {
    tags() {
      return tagModule.activatedTags;
    },
    recursiveTasks(): ScheduledReportResponse[] {
      return scheduledReportModule.scheduledReports;
    },
  },

  methods: {
    updateTask(task: ScheduledReportResponse) {
      scheduledReportModule.updateScheduledReport(task);
    },
    updateNewTask(newTask: {
      task: string;
      hour?: number | null | undefined;
      tag?: string | null | undefined;
      weekday: number;
    }) {
      this.newTask = newTask;
    },
    async createNewTask() {
      await todayViewModule.createScheduledReport(this.newTask);
      this.newTask = {
        task: '',
        hour: null,
        tag: null,
        weekday: 0,
      };
    },
    async deleteTask(task: ScheduledReportResponse) {
      todayViewModule.deleteScheduledReport(task);
    },
  },

  async mounted() {
  },
});
