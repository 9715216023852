


































































































import Vue from 'vue';

// types
import { TodayUser, ChatReaction } from '@/model/types';
import { ChatResponse, ChatReactionResponse } from 'remonade-api/lib';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';

// components
import S3Image from '@/components/atoms/S3Image.vue';
import ChatReactionList from '@/components/molecules/chat/ChatReactionList.vue';
import EmojiPicker from '@/components/molecules/common/EmojiPicker.vue';

// services
import dayjs from 'dayjs';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { sendEvent } from '@/service/GoogleAnalyticsService';

interface DataType {
  message: string;
  showFormTip: boolean;
  emojiChatId: string | null;
}

export default Vue.extend({
  name: 'TodayChatsContainer',

  components: {
    S3Image,
    ChatReactionList,
    EmojiPicker,
  },

  data: (): DataType => {
    return {
      message: '',
      showFormTip: false,
      emojiChatId: null,
    };
  },

  computed: {
    isChatMinified() {
      return todayViewModule.isChatMinified;
    },
    toggleIconName() {
      return todayViewModule.isChatMinified ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right';
    },
    chatsWithReactions() {
      return todayViewModule.chatsWithReactions;
    },
    teamUsers(): TodayUser[] {
      if (todayViewModule.me) {
        return [...todayViewModule.teamUsers, todayViewModule.me];
      }
      return todayViewModule.teamUsers;
    },
    chatCellStyle() {
      if (this.isChatMinified) {
        return {
          display: 'inline-block',
          marginLeft: '32px',
        };
      }
      return {
        display: 'inline-block',
      };
    },
    emojiPickerStyle() {
      if (this.emojiChatId) {
        const target = this.$refs[this.emojiChatId];
        if (target) {
          if (Array.isArray(target)) {
            const div = this.$refs[this.emojiChatId][0] as HTMLDivElement;
            return {
              top: `${div.getBoundingClientRect().top}px`,
            };
          }
        }
      }
      return {};
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
  },

  methods: {
    toggleChat() {
      todayViewModule.toggleChat();
    },
    showEmoji(userId: string) {
      // 自分を例外処理
      const user = this.teamUsers.find((u) => u.userId === userId);
      if (user) {
        return user.showEmoji;
      }
      return false;
    },
    getIcon(userId: string) {
      const user = this.teamUsers.find((u) => u.userId === userId);
      if (user) {
        if (user.showEmoji) {
          return user.emoji;
        }
        return user.iconUrl;
      }
      return null;
    },
    getUserName(userId: string) {
      const find = this.teamUsers.find((u) => u.userId === userId);
      if (find) {
        return find.username;
      }
      return '';
    },
    getDisplayDate(chat: ChatResponse) {
      const date = dayjs(chat.createdAt).format('HH:mm');
      return date;
    },
    isMine(userId: string) {
      if (todayViewModule.me) {
        return userId === todayViewModule.me.userId;
      }
      return false;
    },
    formatChat(message: string) {
      const rows = message.split('\n').map((c) => `<p>${c}</p>`).join('');
      const regexpUrl = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
      const regexpMakeLink = (all, url, h, href) => {
          return '<a target=\"_blank\" href="h' + href + '">' + url + '</a>';
      };
      return rows.replace(regexpUrl, regexpMakeLink);
    },
    toggleEmojiPicker(chatId: string) {
      this.emojiChatId = chatId;
    },
    async pickEmoji(emoji: string) {
      if (this.emojiChatId) {
        commonModule.setLoader(true);
        await todayViewModule.addReaction({ emoji, chatId: this.emojiChatId });
        commonModule.setLoader(false);
        this.emojiChatId = null;

        sendEvent('chat_reacton_create', {});
      }
    },
    resetFocus() {
      this.emojiChatId = null;
    },
    async clickReaction(reaction: ChatReaction, chatId: string) {
      commonModule.setLoader(true);
      await todayViewModule.clickReaction({ reaction, chatId });
      commonModule.setLoader(false);

      sendEvent('chat_reacton_click', {});
    },
    async sendMessage() {
      if (this.message.trim().length > 0) {
        const sendingMessage = this.message;
        this.message = '';
        commonModule.setLoader(true);
        await todayViewModule.sendChat(sendingMessage);
        commonModule.setLoader(false);

        sendEvent('chat_message_create', {});
        this.$intercom.trackEvent('chat_message_create');
      }
    },
    chatTime(time: string): string {
      return dayjs(time).format('HH:mm');
    },
  },
});
