






















































































import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import Vue from 'vue';

interface DataType {
  isDeleteDialogOpen: boolean;
  isDeleting: boolean;
}

export default Vue.extend({
  name: 'ChannelSettingContainer',

  data: (): DataType => {
    return {
      isDeleteDialogOpen: false,
      isDeleting: false,
    };
  },

  computed: {
    channel() {
      if (todayViewModule.selectedChannelId !== 'ALL') {
        return channelsModule.channels.find((c) => c.channelId === todayViewModule.selectedChannelId);
      }
    },
    isAll() {
      return todayViewModule.selectedChannelId === 'ALL';
    },
  },

  methods: {
    async updateChannelName(channelName: string) {
      await channelsModule.updateChannel({ channelId: this.channel.channelId, channelName});
      await channelsModule.listChannels();
    },
    async deleteChannel() {
      this.isDeleting = true;
      await channelsModule.deleteChannel(this.channel.channelId);
      await channelsModule.listChannels();
      this.isDeleting = false;
      this.isDeleteDialogOpen = false;
      todayViewModule.selectChannel('ALL');
    },
  },
});
