



































































































































import Vue, {PropType} from 'vue';
import { TodayUser } from '@/model/types';

// components
import RadialProgressBar from 'vue-radial-progress';
import S3Image from '@/components/atoms/S3Image.vue';

interface DataType {
  gridWidth: number;
  strokeWidth: number;
}

export default Vue.extend({
  name: 'TodayUserCard',

  components: {
    RadialProgressBar,
    S3Image,
  },

  props: {
    user: {
      required: true,
      type: Object as PropType<TodayUser>,
    },
    isMine: {
      required: false,
      default: false,
      type: Boolean,
    },
    hoverable: {
      required: false,
      default: true,
      type: Boolean,
    },
    journal: {
      required: false,
      default: '',
      type: String,
    },
  },

  data: (): DataType => {
    return {
      gridWidth: 100.0,
      strokeWidth: 20.0,
    };
  },

  computed: {
    innerStyle(): object {
      return {
        width: this.snapSize,
        height: this.snapSize,
      };
    },
    snapSize(): string {
      return (this.gridWidth - this.strokeWidth * 3) + 'px';
    },
    circleLeftStyle(): string {
      if (this.snapSize) {
        return `border-bottom-left-radius: ${this.gridWidth * 2}px; border-top-left-radius: ${this.gridWidth * 2}px;`;
      }
      return '';
    },
    circleRightStyle(): string {
      if (this.snapSize) {
        return `border-bottom-right-radius: ${this.gridWidth * 2}px; border-top-right-radius: ${this.gridWidth * 2}px;`;
      }
      return '';
    },
    stopColor(): string {
      const rate = this.user.completedHours / this.user.totalHours;
      if (rate > .7) {
        return '#00D6C7';
      }
      if (rate > .4) {
        return '#95D6D2';
      }
      return '#A6C3C2';
    },
    startColor(): string {
      const rate = this.user.completedHours / this.user.totalHours;
      if (rate > .7) {
        return '#00AF74';
      }
      if (rate > .4) {
        return '#84D9BD';
      }
      return '#B2C9C1';
    },
    journalBadgeColor() {
      if (!this.journal || this.journal.trim().length === 0) {
        if (this.isMine) {
          return 'danger';
        }
        return 'grey';
      }
      return 'primary';
    },
  },

  methods: {
    handleResize() {
      const userCard = this.$refs.userCard as HTMLDivElement;
      if (userCard) {
        const cardWidth = userCard.clientWidth;
        this.gridWidth = cardWidth;
        // this.strokeWidth = 8;
      }
    },
    openEmoji() {
      this.$emit('openEmoji');
    },
    openTasks() {
      this.$emit('openTasks');
    },
    openActivities() {
      this.$emit('openActivities');
    },
    getCellColor(rate: number) {
      if (rate > 70) {
        return '#00AF74';
      } else if (rate > 40) {
        return '#84D9BD';
      }
      return '#B2C9C1';
    },
    clickStats() {
      this.$emit('clickStats');
    },
    clickJournal() {
      this.$emit('clickJournal');
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.$nextTick();
    this.handleResize();
  },
});
