




















































import Vue from 'vue';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';

// components
import ChannelMembersContainer from '@/container/channel/ChannelMembersContainer.vue';
import RecursiveTaskContainer from '@/container/today/RecursiveTaskContainer.vue';
import TodayBookmarkContainer from '@/container/today/TodayBookmarkContainer.vue';
import TodaySettingContainer from '@/container/today/TodaySettingContainer.vue';
import TodayTasksContainer from '@/container/today/TodayTasksContainer.vue';
import TodayProgressContainer from '@/container/today/TodayProgressContainer.vue';
import TodayJournalContainer from '@/container/today/TodayJournalContainer.vue';
import SelectedUserStatsContainer from '@/container/stats/SelectedUserStatsContainer.vue';
import TodayJournalDialogContainer from '@/container/today/TodayJournalDialogContainer.vue';
import ChannelSettingContainer from '@/container/channel/ChannelSettingContainer.vue';

// service
import { calcDoneRate } from '@/service/ui/StatsService';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { userModel } from 'remonade-api/src/model/dynamodb/userModel';
import { userModule } from '@/store/dataModule/user/userModule';

import { sendEvent } from '@/service/GoogleAnalyticsService';

export default Vue.extend({
  name: 'TodayGridContainer',

  components: {
    ChannelMembersContainer,
    TodayBookmarkContainer,
    RecursiveTaskContainer,
    TodayTasksContainer,
    TodayProgressContainer,
    TodaySettingContainer,
    SelectedUserStatsContainer,
    TodayJournalDialogContainer,
    TodayJournalContainer,
    ChannelSettingContainer,
  },

  data: () => {
    return {
      tabIndex: 0,
    };
  },

  watch: {
    tabIndex: {
      handler() {
        sendEvent('today_tab_change', {
          index: this.tabIndex,
        });
      },
    },
  },

  computed: {
    appbarControlsStyle() {
      return {
        top: 0,
        right: todayViewModule.isChatMinified ?  `56px` : `256px`,
        bottom: 0,
        left: `${200 + 240}px`,
        position: `absolute`,
      };
    },
    appbarPresenseStyle() {
      return {
        top: 0,
        right: todayViewModule.isChatMinified ?  `56px` : `256px`,
        bottom: 0,
        left: `${200}px`,
        position: `absolute`,
      };
    },
    todayGridWrapperStyle() {
      return {
        marginRight: todayViewModule.isChatMinified ?  `56px` : `256px`,
      };
    },
    presenceUsersLength() {
      return todayViewModule.selectedChannelPresenceUserLength;
    },
    channelUsersLength() {
      return todayViewModule.selectedChannelUsers.length + 1; // 自分を含める
    },
    selectedChannelId() {
      return todayViewModule.selectedChannelId;
    },
  },

  methods: {
    onScroll(event: any) {
      // console.log(event);
    },
  },
});
