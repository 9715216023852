



























































































































import Vue from 'vue';

import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { TodayUser } from '@/model/types';
import { userModule } from '@/store/dataModule/user/userModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { commonModule } from '@/store/viewModule/common/commonModule';

interface DataType {
  isInviteDialogOpen: boolean;
  invitingUserIds: string[];
  isInviting: boolean;
}

export default Vue.extend({
  name: 'ChannelMembersContainer',

  data: (): DataType => {
    return {
      isInviteDialogOpen: false,
      invitingUserIds: [],
      isInviting: false,
    };
  },

  computed: {
    selectedChannelId() {
      return todayViewModule.selectedChannelId;
    },
    presenceUsersLength() {
      return todayViewModule.selectedChannelPresenceUserLength;
    },
    channelUsersLength() {
      return todayViewModule.selectedChannelUsers.length + 1; // 自分を含める
    },
    channelUsers(): TodayUser[] {
      return todayViewModule.selectedChannelUsers;
    },
    teamUsers(): TodayUser[] {
      return todayViewModule.teamUsers;
    },
    optionUsers(): TodayUser[] {
      return this.teamUsers.filter((user) => {
        const find = this.channelUsers.find((u) => u.userId === user.userId);
        if (find) {
          return false;
        }
        return true;
      });
    },
    isAll() {
      return todayViewModule.selectedChannelId === 'ALL';
    },
  },

  methods: {
    openInviteDialog() {
      this.isInviteDialogOpen = true;
    },
    async invite() {
      this.isInviting = true;
      await Promise.all(this.invitingUserIds.map((userId) => channelsModule.inviteUserToChannel({ channelId: this.selectedChannelId, userId, userType: 'general' })));
      this.isInviteDialogOpen = false;
      this.invitingUserIds = [];
      this.isInviting = false;
    },
    async removeUser(user: TodayUser) {
      await channelsModule.removeUserFromChannel({ channelId: this.selectedChannelId, userId: user.userId });
    },
  },
});
